
export default {
  name: 'AiSectionTitle',
  props: {
    fontStyle: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: String,
      default: ''
    },
    size: {
      type: [Number, String],
      default: 14
    }
  },

  setup: () => {
    return {

    }
  }
}
